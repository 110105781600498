/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridItem } from '@chakra-ui/react';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { useCartContext } from 'context/CartContext/CartContext';
import { GeneralConcertInfo } from './ConcertInfo/ConcertInfo';
import { TicketCart } from 'pages/EventPage/TicketCart/TicketCart';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Hall } from './Hall/Hall';
import { useConcertQuery } from './hooks/useConcert';
export const SingleConcertPage = () => {
  const { eventKey } = useParams();
  const { data, isFetching, isError } = useConcertQuery(eventKey);
  const { tickets, setEventId, setEventKey } = useCartContext();

  useEffect(() => {
    if (data && !isFetching) {
      setEventId(data.concert.eventId);
      setEventKey(data.concert.eventKey);
    }
  }, [data?.concert.eventId, data?.concert.id]);

  if (isFetching) {
    return <LoaderOverlay />;
  }

  return !isError && data ? (
    <>
      <GeneralConcertInfo {...data.concert} />

      <Grid mx='auto' maxW='1440px' templateColumns={{ base: '1fr', md: '460px 1fr' }}>
        <GridItem h={{ md: '1000px' }} minH='230px'>
          <TicketCart currency={data.concert.currency} selectedTickets={tickets} />
        </GridItem>
        <GridItem rowStart={{ base: 1, md: 'auto' }} alignSelf='start' h={{ md: '1000px' }}>
          <Hall
            pricing={data.pricing}
            event={data.concert.eventKey}
            currency={data.concert.currency}
            maxAllowedTickets={data.maxAllowedTickets}
          />
        </GridItem>
      </Grid>
    </>
  ) : null;
};
